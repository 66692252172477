<template>
  <div style="width: 100%">
    <el-form :model="form" :rules="rules" ref="postForm" label-position="right" label-width="90px" size="medium">
      <el-form-item label="学段：" prop="period_name">
        <el-select v-model="form.period_name" placeholder="请选择" @change="handlePeriodChange">
          <el-option v-for="item in selection.periodList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="校区：" prop="campus_name">
        <el-select v-model="form.campus_name" placeholder="请选择" @change="handleCampusChange">
          <el-option v-for="item in selection.campusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="毕业年份：" prop="entrance_year">
        <el-select style="margin-right: 28px" v-model="form.entrance_year" placeholder="请选择" @change="handleYearChange">
          <el-option v-for="(item, index) in selection.yearList" :label="item.name" :key="index" :value="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班级：" prop="class_id">
        <el-select v-model="form.class_id" placeholder="请选择">
          <el-option v-for="item in selection.ClassList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { searchClass } from "../../api/member/class-message";

export default {
  props: {
    selection: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        id: 0,
        class_id: "",
        campus_name: "", // 校区
        period_name: "",
        entrance_year: "",
      },
      rules: {
        class_id: [{ required: true, validator: this.classIdPass, trigger: "blur" }],
        period_name: [{ required: true, validator: this.periodPass, trigger: "blur" }],
        campus_name: [{ required: true, validator: this.campusPass, trigger: "blur" }],
        entrance_year: [{ required: true, validator: this.yearPass, trigger: "blur" }],
      },
    };
  },
  created() {
    this.getSearch();
  },
  methods: {
    handlePeriodChange() {
      this.form.campus_name = ''
      this.form.entrance_year = ''
      this.form.class_id = ''
    },
    handleCampusChange() {
      this.form.entrance_year = ''
      this.form.class_id = ''
    },
    handleYearChange() {
      this.form.class_id = ''
    },
    getClassInfo() {
      console.log("getClassInfo: ", this.form.class_id);
      return [
        {
          class_id: this.form.class_id,
          on_show: 1,
        },
      ];
    },
    updateData() {},
    getSearch(data) {
      console.log("getSearch");
      searchClass(data)
        .then((res) => {
          this.selection.periodList = res.data.period;
          this.selection.campusList = res.data.campus;
          this.selection.yearList = res.data.entrance_year;
          this.selection.ClassList = res.data.class;
          console.log("this.selection---", this.selection);
        })
        .catch((err) => console.log(err));
    },
    validate(callback = () => {}, invalidCallback = () => {}) {
      this.$refs["postForm"].validate((valid) => {
        if (valid) {
          callback();
        } else {
          invalidCallback();
        }
      });
    },
    // 名称验证
    classIdPass(rule, value, callback) {
      if (value) {
        callback();
      } else {
        callback(callback(new Error("请选择班别")));
      }
    },
    // 学段验证
    periodPass(rule, value, callback) {
      if (value) {
        callback();
      } else {
        callback(new Error("请选择学段"));
      }
    },
    // 校区校验
    campusPass(rule, value, callback) {
      if (value) {
        callback();
      } else {
        callback(callback(new Error("请选择校区")));
      }
    },
    // 年份校验
    yearPass(rule, value, callback) {
      if (value) {
        callback();
      } else {
        callback(callback(new Error("请选择毕业年份")));
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val, oldVal) {
        const data = {
          period_id: val.period_name,
          campus_id: val.campus_name,
          entrance_year: val.entrance_year,
        };
        this.getSearch(data);
      },
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
