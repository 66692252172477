<template>
  <div class="base-msg">
    <p class="title">基础信息</p>
    <div class="base-content">
      <!-- <div class="avatar">
        <div class="content-title">头像：</div>
        <SingleMediaWall
          style="margin-left: 20px"
          :width="80"
          :height="80"
          borderRadius="50%"
          :showMask="false"
          :MediaData="defaultAvatar"
          :useDefaultAvatar="true"
        />
      </div> -->
      <div class="form">
        <el-form class="form-item" :rules="rules" :model="formItem" ref="postForm" label-position="right" label-width="90px" size="medium">
          <el-form-item label="用户名称：" prop="name">
            <el-input style="width: 201px; margin-right: 60px" v-model="formItem.name" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content">
      <p class="title">班级信息</p>
      <!-- <ClassList @updateClassData="updateClassData" /> -->
      <AddClassPlane
        style="margin-top: 40px"
        not-post
        :formItem="form"
        :ClassForm="ClassForm"
        :selection="selection"
        ref="acp"
      />
    </div>
    <FixedActionBar>
      <el-button @click="GoBack">返回</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";
import { addNoAuthStudent, ClassMembers } from "../../api/school-student-list";
import AddClassPlane from "../../components/Member/AddClassPlane.vue";
// import ClassList from "../../components/Member/ClassList.vue";

export default {
  data() {
    return {
      ClassForm: [], // 班级列表
      selection: {
        periodList: [],
        campusList: [],
        yearList: [],
        ClassList: [],
      },
      form: {
        id: 0,
        class_id: "",
        campus_name: "", // 校区
        period_name: "",
        entrance_year: "",
      },
      detailForm: null,
      formItem: {
        id: this.$deCode(this.$route.params.data).id,
        class_id: this.$deCode(this.$route.params.data).class_id,
        name: "",
        class_info: [],
      },
      isNext: "",
      defaultAvatar: window.serverConfig.VUE_APP_ADMINURL + "/cyc/images/wxapps/icons/avatar1.png",
      classData: [],
      rules: {
        name: [{ required: true, validator: this.checkName, trigger: "blur" }],
      },
    };
  },
  methods: {
    checkName(rule, value, callback) {
      console.log("check: ", value);
      if (value) {
        callback();
      } else {
        callback(new Error("请填写用户名称"));
      }
    },
    GoBack() {
      this.$router.go(-1);
    },
    save() {
      this.$refs.acp.validate(
        () => {
          console.log("ok");
          const class_info = this.$refs.acp.getClassInfo();
          // then
          if (this.formItem.name === "") {
            this.$message.error("请输入用户名称");
          } else {
            //   const data = [];
            //   for (var i = 0; i < this.classData.length; i++) {
            //     const d = {};
            //     d.class_id = this.classData[i].class_id;
            //     if (!this.classData[i].on_show) {
            //       d.on_show = 0;
            //     } else {
            //       d.on_show = this.classData[i].on_show;
            //     }
            //     data.push(d);
            //   }
            //   console.log(data);
            //   console.log(this.classData);
            this.formItem.class_info = class_info;
            // TODO: 这里加第二个
            addNoAuthStudent(this.formItem)
              .then((res) => {
                this.$message.success("添加成功");
                return this.$router.go(-1);
              })
              .catch((err) => console.log(err));
          }
        },
        () => {
          this.$message.error("请选择班级信息");
        }
      );
      return;
    },
    updateClassData(res) {
      this.classData = res;
    },
    // 获取校友信息
    getList() {
      // TODO: 这个接口没法获取班级信息所选字段！！！
      ClassMembers({ class_id: this.formItem.class_id })
        .then((res) => {
          const data = res.data.data;
          console.log(data);
          for (var i = 0; i < data.length; i++) {
            if (this.formItem.id === data[i].id) {
              this.formItem = data[i];
              this.defaultAvatar = data[i].avatar;
            }
          }
          console.log(this.formItem);
        })
        .catch((err) => {});
    },
  },
  created() {
    this.getList();
  },
  components: { SingleMediaWall, FixedActionBar, AddClassPlane },
};
</script>

<style lang="scss" scoped>
.base-msg {
  .title {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
    border-left: 3px #3479ef solid;
  }
}
.base-content {
  .avatar {
    font-size: 14px;
    margin: 40px 0 0 20px;
    display: flex;
    .content-title {
    }
  }
  .form {
    margin-top: 20px;
    .is-required {
      color: red;
    }
    .base-content {
      .avatar {
        font-size: 14px;
        margin: 40px 0 0 20px;
        display: flex;
        .content-title {
        }
      }
      .form {
        margin-top: 20px;
        .form-item {
          color: red;
        }
      }
    }
    .content {
      margin-top: 50px;
      p {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
